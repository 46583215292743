import { graphql, PageProps } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Portfolio, Project } from "../../@types/gitconnected";
import ProjectModal from "../components/projectModal";
import TimelineCard from "../components/timelineCard";
import createKey from "../utils";

interface PropsPortfolio {
  portfolio: Portfolio;
}

const IndexPage: React.FC<PageProps<PropsPortfolio>> = ({ data }) => {
  const { basics, projects } = data.portfolio;
  const [openProject, setOpenProject] = useState<Project>(null);

  const handleCloseModalEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") setOpenProject(null);
  };

  useEffect(() => {
    document.addEventListener("keyup", handleCloseModalEscape, false);
  });

  return (
    <>
      <Helmet>
        <title>Personal Projects Portfolio</title>
      </Helmet>
      <ProjectModal project={openProject} closeModal={() => setOpenProject(null)} />
      <div className="container mx-auto mt-12 mb-12">
        <img src={basics.picture} alt="profil" className="w-48 rounded-full mr-auto ml-auto shadow-md" />
        <h1 className="text-center mt-5 font-bold text-xl">Yoan Mettan - Switzerland</h1>
      </div>
      <div className="container mx-auto content-center w-full sm:w-2/3 md:w-1/2">
        <div className="border-blue-basic border-l-4 pt-4 pb-6">
          <div className="timeline-cards pt-1 pb-2 mb-3 grid gap-y-6 overflow-hidden">
            {projects.map((project) => <TimelineCard key={createKey(project.displayName)} project={project} openModal={setOpenProject} />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

export const query = graphql`
{
  portfolio {
    basics {
      picture
    }
    projects {
      displayName
      description
      languages
      libraries
      name
      summary
      startDate
      endDate
      roles
    }
  }
}

`;
