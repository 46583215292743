import * as React from "react";

import java from "../images/languages/java.svg";
import javascript from "../images/languages/javascript.svg";
import css from "../images/languages/css.svg";
import html from "../images/languages/html.svg";
import kotlin from "../images/languages/kotlin.svg";
import php from "../images/languages/php.svg";
import typescript from "../images/languages/typescript.svg";
import powershell from "../images/languages/powershell.webp";
import analytics from "../images/libs/analytics.svg";
import gtm from "../images/libs/gtm.svg";
import jquery from "../images/libs/jquery.svg";
import maven from "../images/libs/maven.svg";
import play from "../images/libs/play.svg";
import react from "../images/libs/react.svg";
import redux from "../images/libs/redux.svg";
import spring from "../images/libs/spring.svg";
import verx from "../images/libs/vertx.svg";
import vmwarepowercli from "../images/libs/vmwarepowercli.png";
import apple from "../images/libs/apple.svg";
import firebase from "../images/libs/firebase.svg";
import hibernate from "../images/libs/hibernate.svg";
import kafka from "../images/libs/kafka.svg";
import mq from "../images/libs/mq.png";
import docker from "../images/libs/docker.svg";
import gitlab from "../images/libs/gitlab.svg";
import grafana from "../images/libs/grafana.svg";
import helm from "../images/libs/helm.svg";
import kubernetes from "../images/libs/kubernetes.svg";
import prometheus from "../images/libs/prometheus.svg";
import apollo from "../images/libs/apollo.svg";
import graphql from "../images/libs/graphql.svg";
import roles from "../images/roles.svg";

interface Props {
  icon: string;
}

const Icon = ({ icon }: Props) => {
  switch (icon.toLowerCase()) {
    case "java":
      return <img className="h-8" src={java} alt="java" />;
    case "javascript":
      return <img className="h-8" src={javascript} alt="javascript" />;
    case "css":
      return <img className="h-8" src={css} alt="css" />;
    case "html":
      return <img className="h-8" src={html} alt="html" />;
    case "kotlin":
      return <img className="h-8" src={kotlin} alt="kotlin" />;
    case "php":
      return <img className="h-8" src={php} alt="php" />;
    case "typescript":
      return <img className="h-8" src={typescript} alt="typescript" />;
    case "powershell":
      return <img className="h-8" src={powershell} alt="powershell" />;
    case "google analytics":
      return <img className="h-8" src={analytics} alt="analytics" />;
    case "google tag manager":
      return <img className="h-8" src={gtm} alt="gtm" />;
    case "jquery":
      return <img className="h-8" src={jquery} alt="jquery" />;
    case "maven":
      return <img className="h-8" src={maven} alt="maven" />;
    case "play framework":
      return <img className="h-8" src={play} alt="play" />;
    case "react":
      return <img className="h-8" src={react} alt="react" />;
    case "redux":
      return <img className="h-8" src={redux} alt="redux" />;
    case "springboot":
      return <img className="h-8" src={spring} alt="spring" />;
    case "vert.x":
      return <img className="h-8" src={verx} alt="vertx" />;
    case "vmware powercli":
      return <img className="h-8" src={vmwarepowercli} alt="vmwarepowercli" />;
    case "firebase":
      return <img className="h-8" src={firebase} alt="firebase" />;
    case "apns":
      return <img className="h-8" src={apple} alt="apple" />;
    case "hibernate":
      return <img className="h-8" src={hibernate} alt="hibernate" />;
    case "kafka":
      return <img className="h-8" src={kafka} alt="kafka" />;
    case "websphere mq":
      return <img className="h-8" src={mq} alt="mq" />;
    case "docker":
      return <img className="h-8" src={docker} alt="docker" />;
    case "gitlabci":
      return <img className="h-8" src={gitlab} alt="gitlabci" />;
    case "grafana":
      return <img className="h-8" src={grafana} alt="grafana" />;
    case "helm":
      return <img className="h-8" src={helm} alt="helm" />;
    case "kubernetes":
      return <img className="h-8" src={kubernetes} alt="kubernetes" />;
    case "prometheus":
      return <img className="h-8" src={prometheus} alt="prometheus" />;
    case "graphql":
      return <img className="h-8" src={graphql} alt="graphql" />;
    case "apollo":
      return <img className="h-8" src={apollo} alt="apollo" />;
    case "roles":
      return <img className="h-8" src={roles} alt="role" />;
    default:
      return <></>;
  }
};

export default Icon;
