import React from "react";

interface TooltipProps {
  text: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({ children, text }) => (
  <div className="tooltip">
    <span className="tooltip-text">{text}</span>
    {children}
  </div>
);

export default Tooltip;
