import React from "react";

import { Project } from "../../@types/gitconnected";

import IconLine from "./iconLine";
import Label from "./label";
import Icon from "./icon";

interface Props {
  project: Project
  closeModal: () => void;
}

const ProjectModal = ({ project, closeModal }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLTextAreaElement).id === "modal") closeModal();
  };

  return (
    <>
      {project && (
        <div
          className="fixed z-20 left-0 top-0 w-full h-full overflow-auto bg-gray-500 bg-opacity-70 pt-10 animated-bg"
          id="modal"
          onKeyUp={() => {}}
          onClick={handleClick}
        >
          <div className="w-full sm:w-2/3 md:w-1/2 mx-auto bg-gray-100 p-8 rounded border-blue-basic border-t-4 animated-slide-top">
            <header className="text-center">
              <Label text={project.displayName} />
            </header>
            <div className="flex flex-row">
              <div className="mt-9 mr-5 place-content-center">
                {project.roles
                  && (
                  <div className="flex flex-row">
                    <Icon icon="roles" />
                    <p className="font-bold ml-2 mt-1">{project.roles}</p>
                  </div>
                  )}
              </div>
              <div className="mt-10">
                <div>
                  <strong>From </strong>
                  {project.startDate}
                  {"  -  "}
                  <strong>To </strong>
                  {project.endDate}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h2 className="font-bold text-xl">Description</h2>
              <p>{project.description}</p>
            </div>
            {project.languages.length > 0 && (
              <div className="mt-10">
                <h2 className="font-bold text-xl">Used languages</h2>
                <IconLine icons={project.languages} />
              </div>
            )}
            <div className="mt-10">
              <h2 className="font-bold text-xl">Used libraries / technologies</h2>
              <IconLine icons={project.libraries} />
            </div>
            <div className="mt-10 text-center">
              <button type="button" className="bg-gray-300 w-20 pl-5 pr-5 pt-1 pb-1 rounded focus:outline-none" onClick={() => closeModal()}>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectModal;
