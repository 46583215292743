import * as React from "react";

import { Project } from "../../@types/gitconnected";
import arrow from "../images/arrow.svg";

import Label from "./label";
import IconLine from "./iconLine";
import Icon from "./icon";

interface Props {
  project: Project;
  openModal: (project: Project) => void;
}

const TimelineCard: React.FC<Props> = ({ project, openModal }) => (
  <div className="timeline-card shadow-md relative ml-4 border-blue-basic border-l-4 rounded bg-white px-4 py-6">
    <Label text={project.displayName} />
    <div className="mt-2">
      <p>{project.summary}</p>
    </div>
    <div>
      <div className="mt-5 mr-5 float-left place-content-center">
        {project.roles
          && (
          <div className="flex flex-row">
            <Icon icon="roles" />
            <p className="font-bold ml-2 mt-1">{project.roles}</p>
          </div>
          )}
      </div>
      <div className="mt-3 mr-5 float-right place-content-center">
        {project.languages.length > 0 && <IconLine icons={project.languages} />}
        {project.languages.length === 0 && project.libraries.length > 0 && <IconLine icons={project.libraries} />}
      </div>
    </div>

    <button
      className="absolute inset-y-0 right-0 bg-gray-400 rounded pl-1 pr-1 mr-1 mt-auto mb-auto focus:outline-none more-details"
      type="button"
      onClick={() => openModal(project)}
    >
      <img src={arrow} alt="arrow" />
    </button>
  </div>
);

export default TimelineCard;
