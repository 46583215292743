import React from "react";

import Icon from "./icon";
import Tooltip from "./tooltip";

interface Props {
  icons: string[];
}

const IconLine = ({ icons }: Props) => (
  <div className="flex flex-row space-x-4 mt-2">
    {icons.sort().map((lang, i) => (
      <Tooltip text={lang} key={`${lang}-${i}`}>
        <Icon icon={lang} />
      </Tooltip>
    ))}
  </div>
);

export default IconLine;
